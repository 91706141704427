import React, { useState } from 'react'
import { ginger } from '../../../styles/fonts'
import { sidebarNavigationActions, sidebarNavigationViews } from '../../../store/features/sidebarNavigation/sidebarNavigationSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faPencil } from '@fortawesome/free-solid-svg-icons'
import { setCookie } from '../../../utils/helperMethods'
import { userKey } from '../../../constants/constants'
import toast from 'react-hot-toast'
import { useAppDispatch } from '../../../store/hooks'
import { authActions } from '../../../store/features/auth/authSlice'
import UserEditForm from "../../../components/global/form/UserEditForm"

const UserProfile = () => {
    const dispatch = useAppDispatch()
    const [isEditingProfile, setIsEditingProfile] = useState(false)

    const handleEditComplete = (user) => {
        setCookie(userKey, user)
        setIsEditingProfile(false)
        dispatch(authActions.setUser(user))
        toast.success("User updated successfully!")
    }

    return (
        <div className='tw-flex tw-flex-col tw-gap-3 tw-px-2' >
            <div className='tw-flex tw-items-center tw-gap-4' >
                <button onClick={() => dispatch(sidebarNavigationActions.setCurrentView(sidebarNavigationViews.main))} className='tw-cursor-pointer tw-w-[24px] tw-h-[24px] tw-rounded-[12px] tw-self-start tw-flex tw-justify-center tw-items-center tw-outline-none tw-border-none tw-bg-[#E8F6ED]' >
                    <FontAwesomeIcon icon={faChevronLeft} width={"7px"} size='lg' color='#000000' />
                </button>
                <p className='tw-p-0 tw-m-0 tw-text-[18px] tw-font-semibold tw-tracking-[1px] tw-flex-1' style={ginger.style} >My Profile</p>
                {/* <button onClick={() => setIsEditingProfile(true)} className='tw-bg-transparent tw-border-none tw-outline-none' >
                    <FontAwesomeIcon icon={faPencil} width={"15px"} color='#1C8067' size='lg' />
                </button> */}
            </div>
            <UserEditForm disabled={!isEditingProfile} onEditComplete={handleEditComplete} />
        </div>
    )
}

export default UserProfile