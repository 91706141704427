const API_BASE_PATH = process.env.NEXT_PUBLIC_BASE_URL

const userKey = 'user_profile';
const tokenKey = 'user_token';
const refreshTokenKey = "refresh_token";
const utmSourceKey = "utm_source"
const utmCampaignKey = "utm_campaign"

const genderEnum = {
    male: "M",
    female: "F",
    other: "P"
}

const patientTypeEnum = {
    myself: "myself",
    others: "others"
}

const orderListingTabsEnum = {
    active: "active",
    past: "past",
    callbacks: "callback"
}

const orderTimelineStatusCodes = {
    ORDER_PRESCRIPTION_SUBMITTED: "ORDER_PRESCRIPTION_SUBMITTED",
    ORDER_PLACED: "ORDER_PLACED",
    ORDER_ON_THE_WAY: "ORDER_ON_THE_WAY",
    DELIVERED: "DELIVERED",
    ORDER_CANCELLED: "ORDER_CANCELLED"
}

const callbackTimelineStatusCodes = {
    NEW: "NEW",
    CALLBACK: "CALLBACK",
    NO_ANSWER: "NO_ANSWER",
    LOST: "LOST",
    CONVERTED: "CONVERTED"
}

const orderStatusCodes = {
    ORDER_PENDING: "ORDER_PENDING",
    ORDER_CANCELLED: "ORDER_CANCELLED",
    OCS_PROCESSING: "OCS_PROCESSING",
    CALL_PENDING: "CALL_PENDING",
    USER_DRX_PENDING: "USER_DRX_PENDING",
    USER_DRX_REJECTED: "USER_DRX_REJECTED",
    USER_DRX_ACCEPTED: "USER_DRX_ACCEPTED",
    SHIPPED_MEDICINES_PACKED: "SHIPPED_MEDICINES_PACKED",
    SHIPPED_IN_TRANSIT: "SHIPPED_IN_TRANSIT",
    DELIVERED: "DELIVERED",
    CONSENT_PENDING: "CONSENT_PENDING",
    CONSENT_GRANTED: "CONSENT_GRANTED",
}

const callbackStatusConstants = {
    NEW: "NEW",
    CALLBACK: "CALLBACK",
    NO_ANSWER: "NO_ANSWER",
    CONVERTED: "CONVERTED",
    LOST: "LOST"
}

const medicineTime = {
    morning: "morning",
    noon: "noon",
    evening: "evening",
    night: "night",
}

const userObjectAttributes = ['id', 'contact_no', 'dob', 'gender', 'mappingIds', 'name', 'country_code', 'profile_pic']

export {
    API_BASE_PATH,
    userKey,
    tokenKey,
    refreshTokenKey,
    utmSourceKey,
    utmCampaignKey,
    genderEnum,
    patientTypeEnum,
    orderListingTabsEnum,
    orderTimelineStatusCodes,
    callbackTimelineStatusCodes,
    orderStatusCodes,
    medicineTime,
    callbackStatusConstants,
    userObjectAttributes
}