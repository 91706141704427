import React, { useEffect, useState } from 'react'
import { ginger } from '../../../styles/fonts'
import toast from 'react-hot-toast'
import { request } from '../../../utils/axiosInstance'
import moment from 'moment'
import images from '../../../constants/images'
import { uiActions } from '../../../store/features/ui/uiSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { genderEnum } from '../../../constants/constants'
import { moEngageEvents } from '../../../constants/moEngageEvents'
import useMobileDetect from '../../../utils/useMobileDetect'

const UserEditForm = ({ wide = false, disabled, onEditComplete }) => {
    const uiState = useAppSelector((state) => state.ui)
    const authState = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()

    const [formData, setFormData] = useState(null)
    const [payload, setPayload] = useState({})
    const [submitDisabled, setSubmitDisabled] = useState(true)

    const currentDevice = useMobileDetect()

    const handleNameInput = (e) => {
        const name = e.target.value

        if (name.match("^[A-Za-z_ ]*$")) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    name
                }
            })
            setPayload((prevState) => {
                return {
                    ...prevState,
                    name
                }
            })
        }
    }

    const handlePhoneInput = (e) => {
        const contact_no = e.target.value
        if (contact_no.length <= 10 && contact_no.match("^[0-9]*$")) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    contact_no
                }
            })
            setPayload((prevState) => {
                return {
                    ...prevState,
                    phone
                }
            })
        }
    }

    const handleSelectGender = (selectedGender) => {
        if (!disabled) {
            window.Moengage.track_event(moEngageEvents.SELECT_GENDER, {
                "Phone Number": authState.user.contact_no,
                Name: formData.name,
                Source: currentDevice.isMobile() ? "mweb" : "web",
                "User Type": "self",
                gender: selectedGender
            });

            setFormData((prevState) => {
                return {
                    ...prevState,
                    gender: selectedGender
                }
            })
            setPayload((prevState) => {
                return {
                    ...prevState,
                    gender: selectedGender
                }
            })
        }
    }

    const handleDOBInput = (dob) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                dob
            }
        })
        setPayload((prevState) => {
            return {
                ...prevState,
                dob
            }
        })
    }

    const handleSubmit = async () => {
        if (moment().diff(formData.dob, 'years') < 18) {
            toast.error("Age must be at least 18 or more.")
            return
        }

        if (payload.hasOwnProperty('dob')) {
            window.Moengage.track_event(moEngageEvents.SELECT_DOB, {
                "Phone Number": authState.user.contact_no,
                Name: formData.name,
                Source: currentDevice.isMobile() ? "mweb" : "web",
                "User Type": "self",
            });
        }

        dispatch(uiActions.setLoading(true))
        const { success, data, error } = await request({
            method: "PUT",
            url: "/user/",
            data: payload
        })

        if (success) {
            const { User } = data
            onEditComplete(User)
            setPayload({})
        } else {
            toast.error(error)
        }

        dispatch(uiActions.setLoading(false))
    }

    useEffect(() => {
        if (formData) {
            if (formData.name.length < 2 || formData.gender === "" || formData.dob === "") {
                setSubmitDisabled(true)
            } else {
                setSubmitDisabled(false)
            }
        }
    }, [formData])

    useEffect(() => {
        setFormData({
            name: authState.user.name,
            gender: authState.user.gender,
            contact_no: authState.user.contact_no,
            dob: authState.user.dob
        })
    }, [])

    return (
        formData && <div className={`tw-flex tw-flex-col tw-gap-3 ${wide && "lg:tw-grid lg:tw-grid-cols-2"}`}>
            <div className='tw-flex tw-flex-col tw-gap-1' >
                <label className='tw-font-medium tw-text-base tw-text-[#424242]' style={ginger.style}>Name</label>
                <div className={`tw-flex tw-items-center tw-gap-2 tw-border tw-border-solid tw-rounded-2xl tw-p-2 ${!disabled && formData?.name?.length > 2 ? "tw-border-[#1C8067]" : "tw-border-[#E2E2EA]"}`} >
                    <input disabled={disabled} value={formData.name} onChange={handleNameInput} className={`tw-flex-1 tw-border-none tw-outline-none tw-text-base tw-font-semibold tw-bg-transparent ${disabled ? "tw-text-[#666666]" : "tw-text-black"}`} style={ginger.style} type='text' autoComplete="off" />
                </div>
            </div>
            {
                <div className='tw-flex tw-flex-col tw-gap-1' >
                    <label className='tw-font-medium tw-text-base tw-text-[#424242]' style={ginger.style}>Phone</label>
                    <div className={`tw-flex tw-opacity-[0.6] tw-items-center tw-gap-2 tw-border tw-border-solid tw-rounded-2xl tw-p-2 tw-border-[#E2E2EA]`} >
                        <p className={`tw-text-base tw-font-semibold tw-m-0 tw-p-0`} style={ginger.style} >+91</p>
                        <input disabled={true} id="contact_no-input" value={formData.contact_no} onChange={handlePhoneInput} placeholder='XXXXX XXXXX' className='tw-flex-1 tw-border-none tw-outline-none tw-text-base tw-font-semibold tw-text-black tw-placeholder-[#E2E2EA] tw-bg-transparent' style={ginger.style} type='tel' autoComplete="off" />
                    </div>
                </div>
            }
            <div className='tw-flex tw-flex-col tw-gap-2' >
                <label className='tw-text-base tw-font-medium tw-text-[#424242]' style={ginger.style} >Gender</label>
                <div className='tw-flex tw-justify-between tw-gap-5' >
                    <button onClick={() => handleSelectGender(genderEnum.male)} className={`tw-flex-1 tw-outline-none tw-border tw-border-solid ${formData.gender === genderEnum.male ? disabled ? "tw-border-[#E2E2EA] tw-bg-[#dcdcdc]" : "tw-border-[#1C8067] tw-bg-[#F4F9F7]" : "tw-border-[#E2E2EA] tw-bg-transparent"} ${disabled ? "tw-text-[#666666]" : "tw-text-black"} tw-rounded-2xl tw-p-3`} >
                        <span className='tw-text-base tw-font-semibold' style={ginger.style}>Male</span>
                    </button>
                    <button onClick={() => handleSelectGender(genderEnum.female)} className={`tw-flex-1 tw-outline-none tw-border tw-border-solid ${formData.gender === genderEnum.female ? disabled ? "tw-border-[#E2E2EA] tw-bg-[#dcdcdc]" : "tw-border-[#1C8067] tw-bg-[#F4F9F7]" : "tw-border-[#E2E2EA] tw-bg-transparent"} ${disabled ? "tw-text-[#666666]" : "tw-text-black"} tw-rounded-2xl tw-p-3`} >
                        <span className='tw-text-base tw-font-semibold' style={ginger.style}>Female</span>
                    </button>
                    <button onClick={() => handleSelectGender(genderEnum.other)} className={`tw-flex-1 tw-outline-none tw-border tw-border-solid ${formData.gender === genderEnum.other ? disabled ? "tw-border-[#E2E2EA] tw-bg-[#dcdcdc]" : "tw-border-[#1C8067] tw-bg-[#F4F9F7]" : "tw-border-[#E2E2EA] tw-bg-transparent"} ${disabled ? "tw-text-[#666666]" : "tw-text-black"} tw-rounded-2xl tw-p-3`} >
                        <span className='tw-text-base tw-font-semibold' style={ginger.style}>Other</span>
                    </button>
                </div>
            </div>
            <div className='tw-flex tw-flex-col tw-gap-2' >
                <label className='tw-text-base tw-font-medium tw-text-[#424242]' style={ginger.style} >Date of Birth</label>
                <div className={`tw-flex tw-items-center tw-gap-2 tw-border tw-border-solid tw-rounded-2xl tw-p-2 ${(formData.dob === "" || disabled) ? "tw-border-[#E2E2EA]" : moment().diff(formData.dob, 'years') < 18 ? "tw-border-[#cc0000]" : "tw-border-[#1C8067]"} tw-bg-transparet`} >
                    <input disabled={disabled} onChange={(e) => handleDOBInput(e.target.value)} value={formData.dob} className={`tw-flex-1 tw-border-none tw-outline-none tw-text-base tw-font-semibold disabled:tw-font-semibold tw-bg-transparent ${disabled ? "tw-text-[#666666]" : "tw-text-[#000]"}`} style={ginger.style} type='date' />
                </div>
            </div>
            {!disabled && <button disabled={submitDisabled} onClick={handleSubmit} className={`${wide && "lg:tw-col-span-2"} tw-text-base lg:tw-py-[15px] tw-font-bold tw-py-[10px]  tw-rounded-3xl tw-border-none tw-outline-none ${submitDisabled ? "tw-bg-[#E5E5E5]" : "tw-bg-black"} tw-text-white`} style={ginger.style} >
                {!uiState.loading && <p className='tw-p-0 tw-m-0' >Save</p>}
                {uiState.loading && <img src={images.loader} className='tw-w-[18px] tw-object-contain' />}
            </button>}
        </div>
    )
}

export default UserEditForm