import { configureStore } from '@reduxjs/toolkit'
import uiSlice from './features/ui/uiSlice'
import authSlice from './features/auth/authSlice'
import orderSlice from './features/order/orderSlice'
import sidebarNavigationSlice from './features/sidebarNavigation/sidebarNavigationSlice'
import consentSlice from './features/consent/consentSlice'
import repeatOrderSlice from './features/repeatOrder/repeatOrderSlice'

const rootReducer = {
    ui: uiSlice,
    auth: authSlice,
    order: orderSlice,
    sidebarNavigation: sidebarNavigationSlice,
    consent: consentSlice,
    repeatOrder: repeatOrderSlice
}

export const store = configureStore({
    reducer: rootReducer
})